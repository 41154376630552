import React from 'react';
import ReactDOM from 'react-dom';

import { Route, BrowserRouter as Router } from 'react-router-dom'

import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';

import App from './App';

const routing = (
  <Router>
    <div className="app-container">
      <Route exact path='/' component={ App } />
    </div>
  </Router>
)

ReactDOM.render(routing, document.getElementById('app'));