import React from 'react';

import { Container, Button, Collapse,  Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';
import './App.scss';

import logo from './imgs/logo-white.png';
import logo2 from './imgs/logo2.png';
import sketch from './imgs/sketch.png';
import tools from './imgs/tools.png';
import checklist from './imgs/checklist.png';

export default class App extends React.Component {
	constructor(props) {
    super(props);

    this.state = {
      nav: false,
      modal: false
    };

    this.modalToggle = this.modalToggle.bind(this);
    this.navToggle = this.navToggle.bind(this);
  }

  navToggle() {
    this.setState({
      nav: !this.state.nav
    });
  }

  modalToggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  listenScrollEvent = e => {
    if (window.scrollY > 50) {
      this.setState({bg: '#111'})
    } else {
      this.setState({bg: 'transparent'})
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenScrollEvent)
  }

  render() {
    return (
      <div className="app-wrapper">
        <Navbar dark expand="md" style={{background: this.state.bg}}>
          <NavbarBrand href="/"><img src={logo} alt="Pallari Construction" width="200"/></NavbarBrand>
          <NavbarToggler onClick={this.navToggle} />
          <Collapse isOpen={this.state.nav} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink href="#about">About</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#services">Services</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#portfolio">Portfolio</NavLink>
              </NavItem>
              <NavItem className="contact">
                <NavLink href="#" onClick={this.modalToggle}>Contact</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
          </Navbar>
        <header id="about">
          <Container className="content">
						<Row>
							<Col xs="12" lg="8">
								<div className="subline">Welcome to Pallari Construction</div>
								<div className="headline">Design & Construction</div>
								<div className="description">Pallari Construction is a general contracting company based in the San Francisco Bay Area committed to providing the best service possible to fulfill each of our clients unique needs.</div>
								<Button onClick={this.modalToggle}>Start A Project</Button>
							</Col>
						</Row>
          </Container>
        </header>
        <section id="services" className="services">
          <Container className="content">
            <Row>
              <Col xs="12">
                <div className="headline">Services</div>
                <div className="subline">What We Do</div>
              </Col>
              <Col xs="12" lg="4">
								<div className="logo"><img src={tools} alt="General Construction" width="35"/></div>
                <div className="title">General Construction</div>
                <div className="description">We provide an experienced, hands-on level of service, getting the job done right, on time and on budget.</div>
              </Col>
              <Col xs="12" lg="4">
                <div className="logo"><img src={sketch} alt="Design + Build" width="35"/></div>
                <div className="title">Design + Build</div>
                <div className="description">We serve as a “single source responsibility” for all facets of the construction process from design through construction.</div>
              </Col>
              <Col xs="12" lg="4">
								<div className="logo"><img src={checklist} alt="Construction Management" width="35"/></div>
                <div className="title">Construction Management</div>
                <div className="description">We manage every aspect of your project with precision and a level of service that ensures success.</div>
              </Col>
            </Row>
          </Container>
        </section>
        <section id="portfolio" className="portfolio">
          <Container className="content">
            <Row>
              <Col xs="12">
                <div className="headline">Under Construction</div>
                <div className="subline">We are currently putting together our portfolio.</div>
              </Col>
            </Row>
          </Container>
        </section>
        <footer>
          <div className="logo"><img src={logo2} alt="Pallari Construction" width="75"/></div>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink href="#about">About</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#services">Services</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#portfolio">Portfolio</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#" onClick={this.modalToggle}>Contact</NavLink>
            </NavItem>
          </Nav>
					<div className="copyright">© Copyright { new Date().getFullYear() }. Pallari Construction. All rights reserved.</div>
        </footer>
        <Modal className="contact" isOpen={this.state.modal} toggle={this.modalToggle} centered="true">
          <ModalHeader toggle={this.modalToggle}></ModalHeader>
          <ModalBody>
            <h4 className="headline">Have questions? Ask away!</h4>
            <hr />
            <div className="category">
              <div className="value">Mike Pallari</div>
              <div className="title">Owner</div>
            </div>
						<div className="category">
              <a href="mailto:pallariconstruction@gmail.com" className="value">pallariconstruction@gmail.com</a>
              <div className="title">Email</div>
            </div>
            <div className="category">
              <div className="value">(415) 519-6383</div>
              <div className="title">Phone</div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}